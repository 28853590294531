import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CakeCard = ({ image, cake }) => {
  return (
    <div className="col-lg-4 col-12">
      <div className="card my-3">
        {image && (
          <GatsbyImage
            image={getImage(image)}
            alt={cake.name}
            className="img-fluid rounded"
          />
        )}
        <div className="card-body">
          <h5 className="card-title">{cake.name}</h5>
        </div>
      </div>
    </div>
  );
};

export default CakeCard;
