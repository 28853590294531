import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import CakesList from "../components/cakes/cakes-list";
import Layout from "../components/layout";
import "../components/layout/custom-css/app.css";
import "../components/layout/custom-css/link.css";

const Tortarendeles = ({ data }) => (
  <Layout>
    <div>
      <div className="container content-wrap">
        <div className="row my-3">
          <div className="col">
            <h1>Tortarendelés</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-lg">
            <GatsbyImage
              image={getImage(data.cake1)}
              alt="Tortarendelés - torta 1"
              className=" img-fluid m-3"
            />
          </div>
          <div className="col-lg">
            <GatsbyImage
              image={getImage(data.cake2)}
              alt="Tortarendelés - torta 2"
              className=" img-fluid m-3"
            />
          </div>
          <div className="col-lg">
            <GatsbyImage
              image={getImage(data.cake3)}
              alt="Tortarendelés - torta 3"
              className=" img-fluid m-3"
            />
          </div>
        </div>

        <div className="row my-3">
          <div className="col">
            <h3 className="text-center">
              Tortáink mind válogatott alapanyagokból, gyümölcsök és magok
              felhasználásával készül.
            </h3>
            <h4 className="text-center">
              Változatosan finom és igazi vitaminbombák:
            </h4>
            <div className="row">
              <div>
                <ul className="ul-circle">
                  <li>
                    Tortáink mindegyike{" "}
                    <strong>glutén-, tej- és cukormentes</strong>
                  </li>
                  <li>
                    <strong>Paleo vagy vegán</strong> étrendet követők is
                    fogyaszthatják
                  </li>
                  <li>
                    A sütés nélkül készült nyers vegán desszertekben 100%-osan
                    megmaradnak a szervezetünket építő enzimek
                  </li>
                  <li>
                    Édesítéshez <strong>aszalt cukrozatlan datolyát</strong>{" "}
                    használunk
                  </li>
                  <li>
                    Tortáink legtöbbjében megtalálható az útifűmaghéj (bolhafű),
                    ami a legjobb természetes méregtelenítő vagy a kókuszolaj,
                    ami a legegészségesebb olajok egyike és természetesen a nap
                    érlelte,
                    <strong>vitamindús gyümölcsök</strong>
                  </li>
                  <li>
                    <strong>Kézműves</strong> finomságainkban mellőzzük a
                    tartósítószert és az egyéb adalékanyagokat
                  </li>
                  <li>
                    Ezeket az <strong>egészséges tortákat</strong>{" "}
                    ételérzékenyeknek, ínyeceknek, gyerekeknek és mindenkinek
                    ajánljuk, aki kíváncsi új ízekre is
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h3>Árak</h3>
            <ul>
              <li>10 szeletes: 11,000 Ft</li>
              <li>12 szeletes: 12,000 Ft</li>
              <li>16 szeletes: 16,000 Ft</li>
              <li>20 szeletes: 20,000 Ft</li>
            </ul>
            <p>Csomagolás: 500 Ft</p>
            <p>
              További információ és rendelés e-mailben:{" "}
              <a
                className="linkDark"
                href="mailto:info@szelencecafe.hu?Subject=Tortarendelés"
                target="_top"
              >
                info@szelencecafe.hu
              </a>
            </p>
          </div>
        </div>

        <CakesList />

        <div className="row">
          <div className="col">
            <h3>Esküvői torták</h3>
            <p>
              Esküvőre egyedi tortarendeléseket is vállalunk. Kísérőtortának,
              menyasszony tortának, vagy fő tortának elkészítjük Neked nyers
              vegán, mindenmentes desszertjeinket a nagy napra. Egyedi
              árajánlattal kapcsolatban itt érdeklődj:{" "}
              <a
                className="linkDark"
                href="mailto:info@szelencecafe.hu?Subject=Esküvői tortarendelés"
                target="_top"
              >
                info@szelencecafe.hu
              </a>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-12 ">
            <div>
              <GatsbyImage
                image={getImage(data.wedding_cake1)}
                className="img-fluid img-thumbnail"
                alt="Esküvői torták 1"
              />
            </div>
          </div>
          <div className="col-lg-4 col-12 ">
            <div>
              <GatsbyImage
                image={getImage(data.wedding_cake2)}
                className="img-fluid img-thumbnail"
                alt="Esküvői torták 2"
              />
            </div>
          </div>
          <div className="col-lg-4 col-12 ">
            <div>
              <GatsbyImage
                image={getImage(data.wedding_cake3)}
                className="img-fluid img-thumbnail"
                alt="Esküvői torták 3"
              />
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col">
            <p>
              A desszertjeinkhez <strong>magokat</strong> használunk, kérünk,
              amennyiben valamire <strong>allergiás</strong> vagy
              tortaválasztásnál előre <strong>jelezd</strong> nekünk. Köszönjük!
            </p>
            <p>
              Ha érdekel hogyan készülnek tortáink és szívesen készítenél otthon
              Te is glutén-, tej- és cukormentes finomságokat, gyere el
              édességkészítő tanfolyamunkra. Ezen a két órás workshopon
              megtanulhatod a nyers vegán édességkészítés fortélyait. Aktuális
              workshopjainkról a Facebook oldalunkon olvashatsz!
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export const pageQuery = graphql`
  query {
    cake1: file(relativePath: { eq: "cakes/duplacsoki.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    cake2: file(relativePath: { eq: "cakes/muffin.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    cake3: file(relativePath: { eq: "cakes/ceklasbananos-sajttorta.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wedding_cake1: file(relativePath: { eq: "wedding/eskuvoi_torta_1.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wedding_cake2: file(relativePath: { eq: "wedding/eskuvoi_torta_2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    wedding_cake3: file(relativePath: { eq: "wedding/eskuvoi_torta_3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export const Head = () => <title>Szelence Café | Tortarendelés</title>;

export default Tortarendeles;
