import React from "react";
import CakeCard from "./cake_card";
import { StaticQuery, graphql } from "gatsby";

const CakesList = () => {
  return (
    <StaticQuery
      query={graphql`
        query CakeQuery {
          allCake {
            edges {
              node {
                id
                image {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
                name
                price
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="row">
          {data.allCake.edges.map((cake) => {
            return (
              <CakeCard
                image={cake.node.image}
                cake={cake.node}
                key={cake.node.id}
              ></CakeCard>
            );
          })}
        </div>
      )}
    />
  );
};

export default CakesList;
